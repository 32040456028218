<template>
    <div id="dc-edit-customer">
        <b-button class="dc-button-back" @click="$router.back()"> <b-icon icon="arrow-left-circle-fill" aria-hidden="true"></b-icon> Back </b-button>

        <h3>Edit Customer</h3>
        <hr />
        <b-form @submit="onSubmitEditCustomer">
            <b-form-group id="dc-companyname-container" label="Company Name">
                <b-form-input v-model="customer.company" placeholder="Enter company name"></b-form-input>
            </b-form-group>

            <b-form-group id="dc-customername-container" label="Customer Name">
                <b-form-input v-model="customer.name" placeholder="Enter customer name"></b-form-input>
            </b-form-group>

            <b-form-group id="dc-balance-container" label="Balance">
                <b-form-input v-model="customer.balance" type="text" placeholder="Enter balance" min="0"></b-form-input>
            </b-form-group>

            <b-form-group id="dc-standing-container" label="Standing(1, 2 or 3)">
                <b-form-input v-model="customer.standing" type="text" placeholder="Enter standing"></b-form-input>
            </b-form-group>

            <b-form-group id="dc-email-container" label="Email address">
                <b-form-input v-model="customer.email" placeholder="Enter email"></b-form-input>
            </b-form-group>

            <b-form-group id="dc-phone-container" label="Phone">
                <b-form-input v-model="customer.phone" type="text" placeholder="Enter phone"></b-form-input>
            </b-form-group>

            <b-form-group id="dc-truck-container" label="Truck">
                <b-form-input v-model="customer.truck" type="text" placeholder="Enter truck"></b-form-input>
            </b-form-group>

            <b-form-group id="dc-quickbooksid-container" label="Quickbooks Id">
                <b-form-input v-model="customer.quickbooksId" type="text" placeholder="Enter Quickbooks id"></b-form-input>
            </b-form-group>

            <b-form-group id="dc-productsid-container" label="Products Id">
                <b-form-input v-model="customer.productsId" type="text" placeholder="Enter Products id"></b-form-input>
            </b-form-group>

            <b-form-group id="dc-address-container" label="Address">
                <b-form-textarea v-model="customer.address" placeholder="Enter address" rows="3" max-rows="4"></b-form-textarea>
            </b-form-group>

            <b-form-group id="dc-notes-container" label="Notes (Optional)">
                <b-form-textarea v-model="customer.notes" placeholder="Enter notes" rows="3" max-rows="4"></b-form-textarea>
            </b-form-group>

            <b-button class="dc-button-primary" type="submit" variant="primary">Save</b-button>
        </b-form>
    </div>
</template>

<script>
import APICalls from "./APICalls";

export default {
    name: "EditCustomer",
    data() {
        return {
            customer: {},
        };
    },
    methods: {
        onSubmitEditCustomer(event) {
            event.preventDefault();
            this.editCustomer(this.customer);
        },
        async editCustomer(params) {
            let id = this.$router.currentRoute.params.id;
            let response = await APICalls.editCustomer(id, params);
            console.log(response);
            this.$toasted.show("Edit customer success!", { position: "bottom-center", className: "dc-toast-success", duration: 6000 });
        },
        async getCustomer() {
            let id = this.$router.currentRoute.params.id;
            let customer = await APICalls.getCustomer(id);
            this.customer = customer;
        },
    },
    mounted() {
        this.getCustomer();
    },
};
</script>

<style lang="scss">
#dc-edit-customer {
    form {
        margin-top: 20px;

        .col-form-label {
            font-size: 20px;
        }

        #dc-companyname-container,
        #dc-customername-container,
        #dc-balance-container,
        #dc-standing-container {
            display: inline-block;
            width: 25%;
        }

        #dc-customername-container,
        #dc-balance-container,
        #dc-standing-container {
            padding-left: 30px;
        }

        #dc-email-container,
        #dc-phone-container,
        #dc-productsid-container {
            display: inline-block;
            width: 25%;
        }

        #dc-truck-container,
        #dc-quickbooksid-container {
            display: inline-block;
            width: 12%;
        }

        #dc-phone-container,
        #dc-truck-container,
        #dc-quickbooksid-container,
        #dc-productsid-container {
            padding-left: 20px;
        }

        #dc-address-container,
        #dc-notes-container {
            display: inline-block;
            width: 50%;
        }

        #dc-notes-container {
            padding-left: 50px;
        }

        .dc-button-primary {
            width: 180px;
            margin-top: 10px;
        }
    }
}
</style>
