import axios from "axios";

import { customer_endpoint } from "./../../constant/api-endpoints";

let createCustomer = function(params) {
    return new Promise((resolve, reject) => {
        axios
            .post(customer_endpoint, params, {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

let deleteCustomer = function(id) {
    return new Promise((resolve, reject) => {
        axios
            .delete(customer_endpoint + "/" + id, {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

let editCustomer = function(id, params) {
    return new Promise((resolve, reject) => {
        axios
            .post(customer_endpoint + "/" + id, params, {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

let getCustomer = function(id) {
    return new Promise((resolve, reject) => {
        axios
            .get(customer_endpoint + "/" + id, {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

let getCustomers = function() {
    return new Promise((resolve, reject) => {
        // let token = ;
        axios
            .get(customer_endpoint, {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

export default { createCustomer, deleteCustomer, editCustomer, getCustomer, getCustomers };
